const apiBase = "/api/v1/webui/{userData.currentAccount}";

export default {
  accountUser: apiBase + "/user",
  accountListWithVirtualWans:
    apiBase + "/virtualwan/users/{userData.currentUser}/accounts",
  enrollmentUrl: "/api/v1/external/zerotrust/hostname",
  enrollmentVerify: "/api/v1/external/zerotrust/enrollment/validate",
  purchaseSubscription: apiBase + "/subscriptions/marketplace/subscribe",
  supportAccessEnabled:
    apiBase + "/portal/accounts/supportaccess/enabled/{enabled}",
  validateSubscription: "/subscriptions/marketplace/validate",
  virtualWans: apiBase + "/virtualwan/{id}",
  evaluation: apiBase + "/subscriptions/signup",
  softwareTerms: "/subscriptions/evaluation/terms",
  managedBandwidthsUsage: apiBase + "/gateways/managed/bandwidths/usage",
  subscriptions: apiBase + "/subscriptions",
  updatesUnacknowledged: apiBase + "/system/pending/update",
  updatesAcknowledge: apiBase + "/system/pending/update/acknowledge/{id}",
  mfeManifest: "/mfe/manifest.json",
};